import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../../components/Layout/SEO';
import Layout from '../../components/Layout/Layout';
import CaseStudyHeader from '../../components/CaseStudy/CaseStudyHeader';
import SectionLight from '../../components/shared/SectionLight';
import CaseStudySectionHeader from '../../components/CaseStudy/CaseStudySectionHeader';
import CaseStudySection from '../../components/CaseStudy/CaseStudySection';
import CaseStudyText from '../../components/CaseStudy/CaseStudyText';
import CaseStudyImage from '../../components/CaseStudy/CaseStudyImage';
import HeadingArrow from '../../components/shared/HeadingArrow';
import Contact from '../../components/shared/Contact';

import data from '../../data/timestamp';

const TimestampPage = ({ location }) => {
	const { name, description, techUsed, sourceLinks } = data;
	const { alt } = data.images.main;
	const { demoLink } = data;

	const links = (
		<div>
			<a href={demoLink.link}>{demoLink.text}</a>
		</div>
	);

	const imageData = useStaticQuery(imageQuery);
	console.log(imageData);
	return (
		<Layout location={location}>
			<SEO title='Timestamp App' />
			<CaseStudyHeader
				title={name}
				text={description}
				image={imageData.mainImg}
				alt={alt}
				links={links}
				sourceLinks={sourceLinks}
				techUsed={techUsed}
			/>
			<SectionLight>
				<CaseStudySectionHeader>
					Capturing Progress in Time
				</CaseStudySectionHeader>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Project Overview</HeadingArrow>
						<p>
							Timestamp is a notes and productivity app with the
							core idea of giving users feedback on how much their
							efforts are accumulating to motivate their growth.
							Users have access to multiple statistics to give
							them instant feedback in the moment or to watch as
							their numbers grow over time.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.landing.childImageSharp.fluid}
						alt='Timestamp dashboard'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Organizing Data</HeadingArrow>
						<p>
							Timestamp was one more data heavy apps that I've
							designed for. Due to the nature of the app
							requirements, I knew that much of my design approach
							would be around creating strong list components that
							had multiple ways of filtering and sorting
							information. Pagination was another key requirement
							feature, due to possibility of the fast scaling data
							growth.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.notelist.childImageSharp.fluid}
						alt='Timestamp note list'
					/>
				</CaseStudySection>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Taking Notes</HeadingArrow>
						<p>
							Next up, I built a custom modal form component that
							lets users easily switch between adding a Project,
							Task, or Note to the app. This form can be accessed
							in multiple ways to present users with options in
							how they use the app. Users can open the modal by
							clicking on a button in the global toolbar menu or
							add an item inline on their current Project or Task
							list.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.createMenu.childImageSharp.fluid}
						alt='Timestamp create menu'
					/>
				</CaseStudySection>

				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Documenting Time</HeadingArrow>
						<p>
							The main data input of Timestamp is called a Note.
							Notes allow users to document what they work on each
							day in a flexible manner. Users can write only a few
							sentence fragments to jog their memory or create
							long-form notes with heavier formatting to organize
							their thoughts. Once a note is entered, it triggers
							automatic scripts that increment a users dashboard
							statistics.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.note.childImageSharp.fluid}
						alt='Timestamp note'
					/>
				</CaseStudySection>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Start to Finish</HeadingArrow>
						<p>
							Users have the option of working on Projects and
							Tasks for as long as they choose or can decide to
							complete an item and move on to something new. Once
							a Project or Task is completed, the app filters it
							into a Completed list where it can easily be
							accessed or reactivated for if a user decides to
							work on it again in the future.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={
							imageData.completedSequence.childImageSharp.fluid
						}
						alt='Timestamp completed actions sequence image'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Searching for Something</HeadingArrow>
						<p>
							Effective search features can help users feel more
							in control of their data. Timestamp gives users the
							ability to filter by Title, Description, Tag, or
							Date to help them refine their search capabilities.
							Results are displayed on cards that give quick
							snapshots of key details for easy skimming and
							filtering.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.search.childImageSharp.fluid}
						alt='Timestamp search page'
					/>
				</CaseStudySection>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Hardwork Pays Off</HeadingArrow>
						<p>
							Users can navigate to the Activity page to see
							multiple statistics related to their effort.
							Timestamp organizes user data and breaks down
							activity by year, month, and daily statistics. Users
							can swap between year stats to see how much their
							effort has grown over time.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.stats.childImageSharp.fluid}
						alt='Timestamp stats page'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Designing in Color</HeadingArrow>
						<p>
							Setting up an app for multiple color modes can be
							challenging at times. Brand colors used for links
							and buttons might look great against a white
							background, but end up being too low contrast when
							it's inverted. Increasing the HSL lightness value of
							the main brand color by a few percentage points can
							keep colors accessible for key users without much
							optical difference on a darker background.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.lightDarkMode.childImageSharp.fluid}
						alt='Timestamp settings page'
					/>
				</CaseStudySection>
				<Contact
					title='Interested in Working Together?'
					linkText='Let Me Know'
				/>
			</SectionLight>
		</Layout>
	);
};

export default TimestampPage;

const imageQuery = graphql`
	query TimestampQuery {
		mainImg: file(
			relativePath: { eq: "case-study/timestamp/timestamp-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		landing: file(
			relativePath: { eq: "case-study/timestamp/app-landing.png" }
		) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		createMenu: file(
			relativePath: { eq: "case-study/timestamp/create-menu.png" }
		) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		lightDarkMode: file(
			relativePath: { eq: "case-study/timestamp/light-dark-mode.png" }
		) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		note: file(relativePath: { eq: "case-study/timestamp/note.png" }) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		search: file(relativePath: { eq: "case-study/timestamp/search.png" }) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		stats: file(relativePath: { eq: "case-study/timestamp/stats.png" }) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		completedSequence: file(
			relativePath: { eq: "case-study/timestamp/completed-sequence.png" }
		) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		notelist: file(
			relativePath: { eq: "case-study/timestamp/notelist.png" }
		) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;
